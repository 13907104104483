import React, { Component, Fragment } from "react";
import ListItem from "./components/ListItem";
import OrderBar from "./components/OrderBar";

class Home extends Component {
  state = {
    asyncStart: "",
    asyncEnd: "",
    selectedItems: [],
    isDisplayed: false,
    orderName: "",
    orderEmail: "",
    orderTimeofArrival:"",
  };

  displayState = () => {
    console.log(this.state.asyncStart);
  };
  updateName = (newName) => {
    this.setState((state) => ({
      orderName: newName,
    }));
  };
  updateEmail = (newemail) => {
    this.setState((state) => ({
      orderEmail: newemail,
    }));
  };
  updateTimeofArrival = (newTime) => {
    this.setState((state) => ({
      orderTimeofArrival: newTime,
    }));
  };
  updateStart = (newStart) => {
    this.setState((state) => ({
      asyncStart: newStart,
    }));
  };
  updateEnd = (newEnd) => {
    this.setState((state) => ({
      asyncEnd: newEnd,
    }));
  };
  updateSelectedItems = (newArray) => {
    this.setState((state) => ({
      selectedItems: newArray,
    }));
  };
  removeSelectedItem = (item) => {
    let newArray = this.state.selectedItems;
    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i].item === item) {
        newArray.splice(i, 1);
        this.updateSelectedItems(newArray);
        break;
      }
    }
  };

  displayOrder = () => {
    if (this.state.isDisplayed) {
      this.setState((state) => ({
        isDisplayed: false,
      }));
    } else {
      this.setState((state) => ({
        isDisplayed: true,
      }));
    }
  };

  render() {
    return (
      <Fragment>
        <main className="bg-gray-200 font-inter pb-20">
          <div className="flex flex-col">
            <div className="bg-black h-20 w-full fixed md:static">
              <img
                src={process.env.PUBLIC_URL + "/assets/header-logo.svg"}
                className="h-12 ml-10 mt-4 mr-36"
                alt="logo de l'etat de fribourg"
              ></img>
            </div>
            <div className="min-h-screen w-full">
              <div className="flex flex-col md:flex-row justify-center">
              <div className="bg-white rounded-xl p-8 m-auto mt-10 md:m-10  w-80 flex-none h-full hover:shadow-lg transition-all duration-200 hidden md:inline ">
                <OrderBar
                  constants={this.props.constants}
                  asyncStart={this.state.asyncStart}
                  asyncEnd={this.state.asyncEnd}
                  selectedItems={this.state.selectedItems}
                  updateStart={this.updateStart}
                  updateEnd={this.updateEnd}
                  updateSelectedItems={this.updateSelectedItems}
                  updateName={this.updateName}
                  orderName={this.state.orderName}
                  updateEmail={this.updateEmail}
                  orderEmail={this.state.orderEmail}
                  updateTimeofArrival={this.updateTimeofArrival}
                  orderTimeofArrival={this.state.orderTimeofArrival}
                /></div>
                <div className="flex flex-col mt-16 md:mt-0">
                  <div className="max-w-96">
                    <ListItem
                      constants={this.props.constants}
                      items={this.state.items}
                      selectedItems={this.state.selectedItems}
                      asyncStart={this.state.asyncStart}
                      asyncEnd={this.state.asyncEnd}
                      updateSelectedItems={this.updateSelectedItems}
                      removeSelectedItem={this.removeSelectedItem}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  "w-full h-cover bg-white fixed  pt-2 px-6 rounded-t-xl shadow-inverse flex flex-col transition-all justify-evenly md:hidden " +
                  (this.state.isDisplayed ? "bottom-0 " : "-bottom-cover")
                }
              >
                <div className="flex flex-row justify-between items-center">
                  <div className={this.state.isDisplayed ? "hidden" : ""}>
                    {(this.state.selectedItems.length===0 ? "Aucun élément séléctionné": (this.state.selectedItems.length === 1 ? "1 élément séléctionné":this.state.selectedItems.length + " éléments séléctionnés"))} 
                  </div>
                  <div
                    className={
                      "rounded-lg cursor-pointer text-center p-3 " +
                      (this.state.isDisplayed
                        ? "bg-gray-200 text-gray-500 h-10 w-full"
                        : "bg-moleson text-white h-12 w-20")
                    }
                    onClick={this.displayOrder}
                  >
                    {this.state.isDisplayed ? "Retour" : "Louer"}
                  </div>
                </div>
                <div className="h-order overflow-auto">
                  <OrderBar
                    constants={this.props.constants}
                    asyncStart={this.state.asyncStart}
                    asyncEnd={this.state.asyncEnd}
                    selectedItems={this.state.selectedItems}
                    updateStart={this.updateStart}
                    updateEnd={this.updateEnd}
                    updateSelectedItems={this.updateSelectedItems}
                    updateName={this.updateName}
                    orderName={this.state.orderName}
                    updateEmail={this.updateEmail}
                    orderEmail={this.state.orderEmail}
                    updateTimeofArrival={this.updateTimeofArrival}
                    orderTimeofArrival={this.state.orderTimeofArrival}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="bg-black h-auto text-white flex items-center justify-center pt-10 pb-4 font-inter mb-14 md:mb-0">
          <div className="flex flex-col md:flex-row">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/footer-logo.svg"}
                alt="logo de l'etat de fribourg"
                className="h-32 mb-10 md:md-0 md:mr-36"
              ></img>
            </div>
            <div className="flex flex-col">
              <div className="font-bold cursor-pointer hover:text-moleson transition-colors duration-100 ease-in-out">
                <a href="https://www.fr.ch/grangeneuve" target="_blank">
                  Grangeneuve
                </a>
              </div>
              <div className="font-bold cursor-pointer hover:text-moleson transition-colors duration-100 ease-in-out">
                Panneau d'administration
              </div>
              <div className="font-bold cursor-pointer hover:text-moleson transition-colors duration-100 ease-in-out">
                <a href="mailto:iag-info@fr.ch">Contact</a>
              </div>
              <div className="border-b-2 border-white my-4"></div>
              <div className="flex flex-row">
                <div>
                  <a href="https://linktr.ee/Grangeneuve" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 hover:text-moleson cursor-pointer transition-colors duration-100 ease-in-out"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7.953 15.066c-.08.163-.08.324-.08.486.08.517.528.897 1.052.89h1.294v4.776c0 .486-.404.89-.89.89H6.577a.898.898 0 0 1-.889-.891v-4.774H.992c-.728 0-1.214-.729-.89-1.377l6.96-12.627a1.065 1.065 0 0 1 1.863 0l2.913 5.585-3.885 7.042zm15.945 0l-6.96-12.627a1.065 1.065 0 0 0-1.862 0l-2.995 5.586 3.885 7.04c.081.164.081.326.081.487-.08.517-.529.897-1.052.89h-1.296v4.776c.005.49.4.887.89.89h2.914a.9.9 0 0 0 .892-.89v-4.775h4.612c.73 0 1.214-.729.89-1.377Z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }

  state = {
    items: [],
    selectedItems: [],
    setAsyncStart: "",
    setAsyncEnd: "",
  };

  componentDidMount() {
    fetch(this.props.constants.API_URL + "/item")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ items: data });
      })
      .catch(console.log);
  }
}

export default Home;
