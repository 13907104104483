import React, { Fragment, useState } from "react";
const OrderBar = ({
  constants,
  asyncStart,
  asyncEnd,
  selectedItems,
  updateStart,
  updateEnd,
  updateSelectedItems,
  updateName,
  updateEmail,
  orderName,
  orderEmail,
  updateTimeofArrival,
  orderTimeofArrival,
}) => {
  const [isProcessing, setProcessing] = useState(0);
  const [isNameValide, setNameValide] = useState(true);
  const [isEmailValide, setEmailValide] = useState(true);
  const [isDateValide, setDateValide] = useState(true);
  const [isHourValide, setHourValide] = useState(true);
  const [isSelectionValide, setSelectionValide] = useState(true);
  const [isSuccesfull, setSuccesfull] = useState(false);
  const [lastOrder, setLastOrder] = useState({
    nom: "",
    email: "",
    debut: "",
    fin: "",
    items: [],
  });

  const updateOrderName = (name) => {
    updateName(name);
  };
  const updateOrderEmail = (email) => {
    updateEmail(email);
  };
  const updateOrderTimeofArrival = (time) => {
    updateTimeofArrival(time);
  };
  const handleStartChange = (e) => {
    updateStart(e.target.value);
  };
  const handleEndChange = (e) => {
    updateEnd(e.target.value);
  };
  const validation = (body) => {
    let valide = true;
    let start = new Date(asyncStart);
    let end = new Date(asyncEnd);
    if (orderName.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || orderName === "") {
      setNameValide(false);
      valide = false;
    } else {
      setNameValide(true);
    }

    if (
      orderEmail.match(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/) ||
      orderEmail === "" ||
      orderEmail.substring(orderEmail.length - 6) !== "@fr.ch"
    ) {
      setEmailValide(false);
      valide = false;
    } else {
      setEmailValide(true);
    }
    if (
      start > end ||
      typeof asyncStart === "undefined" ||
      typeof asyncEnd === "undefined"
    ) {
      setDateValide(false);
      valide = false;
    } else {
      setDateValide(true);
    }
    if (selectedItems.length <= 0) {
      setSelectionValide(false);
      valide = false;
    } else {
      setSelectionValide(true);
    }
    if (orderTimeofArrival === "") {
      setHourValide(false);
      valide = false;
    } else {
      setHourValide(true);
    }
    return valide;
  };
  const handleClick = async (e) => {
    try {
      setProcessing(1);
      setSuccesfull(false);

      const body = {
        nom: orderName,
        email: orderEmail,
        debut: asyncStart,
        fin: asyncEnd,
        items: selectedItems,
        pickupTime: orderTimeofArrival,
      };
      if (validation(body)) {
        const response = await fetch(constants.API_URL + "/createlocation", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        });
        setLastOrder(body);
        setProcessing(0);
        setSuccesfull(true);
        updateOrderName("");
        updateOrderEmail("");
        updateOrderTimeofArrival("");
        updateStart("");
        updateEnd("");
        updateSelectedItems([]);
      } else {
        setProcessing(0);
      }
    } catch (err) {
      console.error(err.message);
      setProcessing(0);
      setSuccesfull(false);
    }
  };

  return (
    <Fragment>
      <div
        id="orderBar"
        
      >
        <div>
          <div className="flex-grow-0">
            <p className={isNameValide ? "" : "text-red-500 font-bold"}>Nom</p>
            <input
              type="text"
              className={
                "rounded-md border border-gray-200 p-4 w-full mb-3 focus:outline-none " +
                (isNameValide ? "" : "border-red-500")
              }
              onChange={(e) => updateOrderName(e.target.value)}
              value={orderName}
            ></input>
            <div
              className={
                "border border-red-500 bg-red-200 text-red-500 rounded-md text-sm p-3 mb-2 " +
                (isNameValide ? "hidden" : "")
              }
            >
              Il y a eu un problème avec le nom que vous avez saisie
            </div>
            <p className={isEmailValide ? "" : "text-red-500 font-bold"}>
              E-mail
            </p>
            <input
              type="email"
              className={
                "rounded-md border border-gray-200 p-4 w-full mb-3 focus:outline-none " +
                (isEmailValide ? "" : "border-red-500")
              }
              onChange={(e) => updateOrderEmail(e.target.value)}
              value={orderEmail}
            ></input>
            <div
              className={
                "border border-red-500 bg-red-200 text-red-500 rounded-md text-sm p-3 mb-2 " +
                (isEmailValide ? "hidden" : "")
              }
            >
              Il y a eu un problème avec l'adresse E-mail que vous avez saisie.
              Vous devez obligatoirement utiliser votre adresse du travail qui
              se termine par @fr.ch
            </div>
            <p>Début de la location</p>
            <input
              type="date"
              id="startDate"
              className={
                "rounded-md  bg-gray-200 p-4 w-full mb-3 focus:outline-none " +
                (isDateValide ? "" : "border border-red-500")
              }
              value={asyncStart}
              onChange={handleStartChange}
            ></input>
            <p>Fin de la location</p>
            <input
              type="date"
              id="endDate"
              className={
                "rounded-md  bg-gray-200 p-4 w-full mb-3 focus:outline-none " +
                (isDateValide ? "" : "border border-red-500")
              }
              onChange={handleEndChange}
              value={asyncEnd}
            ></input>
            <div
              className={
                "border border-red-500 bg-red-200 text-red-500 rounded-md text-sm p-3 mb-2 " +
                (isDateValide ? "hidden" : "")
              }
            >
              Il y a eu un problème avec les dates que vous avez saisie
            </div>
            <div>Heure d'arrivé</div>
            <input
              className={
                "rounded-md bg-gray-200 p-4 w-full mb-3 focus:outline-none " +
                (isHourValide ? "" : "border border-red-500")
              }
              type="time"
              min="08:00"
              max="18:00"
              step="900"
              onChange={(e) => updateOrderTimeofArrival(e.target.value)}
              value={orderTimeofArrival}
            ></input>
            <div
              className={
                "border border-red-500 bg-red-200 text-red-500 rounded-md text-sm p-3 mb-2 " +
                (isHourValide ? "hidden" : "")
              }
            >
              Il y a eu un problème avec l'heure que vous avez saisie
            </div>
          </div>
          <div className="w-full flex-grow">
            <div className="border-t border-gray-400 my-2"></div>
            {selectedItems.length === 0 ? (
              <div
                className={
                  "text-sm " +
                  (isSelectionValide
                    ? "text-gray-400"
                    : "text-red-500 font-bold")
                }
              >
                Selectionnez l'équipement que vous souhaitez louer.
              </div>
            ) : (
              ""
            )}
            {selectedItems.map((item, index) => (
              <div
                key={item.item.pk_item}
                className="rounded-xl border border-gray-200 p-3 text-sm font-bold my-1"
              >
                {item.item.nom}
              </div>
            ))}
          </div>
          <div
            className={
              "bg-green-100 border border-green-400 text-green-500 text-sm rounded-md p-3 mt-3 " +
              (isSuccesfull ? "" : "hidden")
            }
          >
            <p className="mb-2 font-bold">
              Merci pour votre location, un email de comfirmation vous a été
              envoyé !
            </p>
            <p className="mb-2 font-bold">Résumé de votre commande :</p>
            <ul className="mb-2">
              {lastOrder.items.map((item, index) => (
                <li key={item.item.pk_item} className="">
                  - {item.item.nom}
                </li>
              ))}
            </ul>
            <p>
              Loué du <span className="font-bold">{lastOrder.debut}</span> au{" "}
              <span className="font-bold">{lastOrder.fin}</span>
            </p>
            <p>
              Au nom de <span className="font-bold">{lastOrder.nom}</span>
            </p>
          </div>
          <button
            className="flex flex-row rounded-md bg-moleson text-white border-2 border-moleson text-xl font-bold text-center shadow-md w-full p-4 mt-4 cursor-pointer flex-grow-0 hover:bg-white hover:text-moleson transition-colors duration-200 focus:outline-none"
            onClick={() => handleClick()}
            type="button"
          >
            <svg
              className={
                isProcessing
                  ? "animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  : "hidden"
              }
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Valider ma location
          </button>
        </div>
      </div>
    </Fragment>
  );
};
export default OrderBar;
