import React, { Fragment, useEffect, useState } from "react";
import ItemElementEdit from "./ItemElementEdit";
const ListItemEdit = ({ constants,updateView }) => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState([]);

  const getAllItem = async () => {
    try {
      const response = await fetch(constants.API_URL + "/item");
      const jsonData = await response.json();
      setItems(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const deleteItem = async (id) => {
    try {
      const response = await fetch(constants.API_URL + "/item/" + id, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      let newArray = [...items];
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i].pk_item === id) {
          newArray.splice(i, 1);
        }
      }
      setItems(newArray);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getAllItem();
  },[]);

  return (
    <Fragment>
      <div className="flex flex-col">
        <div className="text-2xl font-bold ml-6 mb-4 mt-14">
          Modifier les objets à louer
        </div>
        <div className="flex flex-row justify-end mx-8 mb-4">
          <div className="relative text-gray-600">
            <input
              type="search"
              name="serch"
              placeholder="Rechercher"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              className="bg-white h-10 px-5 pr-10 w-96 rounded-md text-sm focus:outline-none focus:shadow-lg hover:shadow-lg transition-all duration-200"
            ></input>
            <div className="absolute right-0 top-0 mt-3 mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <button
            className="bg-black rounded-md text-white border-2 border-black px-3 ml-6 hover:text-black hover:bg-white transition-colors ease-in-out duration-200"
            onClick={() => updateView(3)}
          >
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <div className="ml-3">Nouveau</div>
            </div>
          </button>
        </div>
        <div className="flex flex-col mb-10">
          {items
            .filter((item) => item.nom.toLowerCase().includes(search))
            .map((item, index) => (
              <ItemElementEdit
                key={item.pk_item}
                constants={constants}
                item={item}
                deleteItem={deleteItem}
              ></ItemElementEdit>
            ))}
        </div>
      </div>
    </Fragment>
  );
};
export default ListItemEdit;
