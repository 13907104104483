import React, { Fragment, useEffect, useState } from "react";
import LocationElementAujourdhui from "./LocationElementAujourdhui";
const Aujourdhui = ({ constants }) => {
  const [locations, setLocation] = useState([]);
  const [listeDates, setListeDates] = useState([]);
  const [today, setToday] = useState(false);

  const getLocationOfTheDay = async () => {
    try {
      const response = await fetch(constants.API_URL + "/locationoftheday");
      const jsonData = await response.json();
      setLocation(jsonData);
      let datearray = [];
      let now = new Date();
      now.setHours(0, 0, 0, 0);
      jsonData.forEach((location) => {
        let datedebut = new Date(location.debut);

        if (datedebut >= now) {
          let dateDebutString = datedebut.toLocaleString("fr-CH").split(",")[0];
          if (datedebut.getTime() === now.getTime()) {
            setToday(true);
            dateDebutString = "Aujourd'hui , " + dateDebutString;
          }
          let debutEntry = {
            prettyDate: dateDebutString,
            rawDate: location.debut,
          };
          if (
            datearray.findIndex((item) => item.rawDate === location.debut) ===
            -1
          ) {
            datearray.push(debutEntry);
          }
        }
        let datefin = new Date(location.fin);
        let dateFinString = datefin.toLocaleString("fr-CH").split(",")[0];
        if (datefin.getTime() === now.getTime()) {
          setToday(true);
          dateFinString = "Aujourd'hui , " + dateFinString;
        }
        let finEntry = { prettyDate: dateFinString, rawDate: location.fin };
        if (
          datearray.findIndex((item) => item.rawDate === location.fin) === -1
        ) {
          datearray.push(finEntry);
        }
      });
      datearray.sort(function (a, b) {
        return new Date(a.rawDate) - new Date(b.rawDate);
      });
      setListeDates(datearray);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getLocationOfTheDay();
  },[]);
  return (
    <Fragment>
      <div>
      <div className={(today ? "hidden" : "")}>
        <div className="text-2xl font-bold ml-6 mb-4 mt-14">Aujourd'hui</div>
        <div
          className=
            "flex flex-col justify-center items-center my-3 "
        >
          <div>
            <img
              src={process.env.PUBLIC_URL + "/assets/no_data.svg"}
              alt="icon no data"
              className="h-40"
            ></img>
          </div>
          <div className="my-6 text-sm">
            Aucune location prévu pour Aujourd'hui
          </div>
        </div></div>
        {listeDates.map((maDate, index) => (
          <div key={index}>
            <div className="text-2xl font-bold ml-6 mb-4 mt-14">
              {maDate.prettyDate}
            </div>
            {locations
              .filter(function (location) {
                return (
                  location.debut === maDate.rawDate ||
                  location.fin === maDate.rawDate
                );
              })
              .map((maLocation, index) => (
                <LocationElementAujourdhui
                  key={maLocation.pk_location}
                  constants={constants}
                  location={maLocation}
                  date={maDate.rawDate}
                ></LocationElementAujourdhui>
              ))}
          </div>
        ))}
      </div>
    </Fragment>
  );
};
export default Aujourdhui;
