import React, { Fragment, useState } from "react";
const CreateItem = ({ constants }) => {
  const [nom, setNom] = useState([]);
  const [description, setDescription] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [displaySuccess, setDisplaySuccess] = useState();

  const createAnItem = async (body) => {
    let response;
    try {
      response = await fetch(constants.API_URL + "/createitem", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      
      setNom("");
      setDescription("");
      setSelectedFile(null);
      setDisplaySuccess(true);
    } catch (err) {
      console.error(err.message);
    }
  };
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", "toasty.png");
    try {
      const response = await fetch(constants.API_URL + "/upload_files", {
        method: "POST",
        body: formData,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleClick = (e) => {
    let body = {
      nom: nom,
      description: description,
      image: selectedFile.name,
    };
    uploadImage();
    createAnItem(body);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <Fragment>
      <div className="text-2xl font-bold ml-6 mb-4 mt-14">
        Créer un nouvel objet à louer
      </div>
      <div
        className={
          "bg-green-200 border text-green-600 border-green-600 rounded p-3 my-3 mx-8 " +
          (displaySuccess ? "" : "hidden")
        }
      >
        Nouvel objet créé avec succès.
      </div>
      <div className="flex flex-col mx-8 bg-white rounded-xl p-3">
        <div>
          <div className="font-bold">Nom</div>
          <input
            className="my-3 p-3 rounded-md border border-gray-300 w-full"
            onChange={(e) => setNom(e.target.value)}
          ></input>
          <div className="font-bold">Description</div>

          <textarea
            className="my-3 p-3 rounded-md border border-gray-300 w-full"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <div className="font-bold">image</div>

          <input
            type="file"
            name="file"
            className="my-3"
            onChange={(e) => changeHandler(e)}
          ></input>
        </div>
        <button
          className="bg-black border-black border-2 rounded-md text-white p-3 px-4 mt-4 w-32 self-end hover:bg-white hover:text-black transition-colors duration-200 ease-in-out"
          onClick={() => handleClick()}
        >
          <div className="flex flex-row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="ml-2">Créer</div>
          </div>
        </button>
      </div>
    </Fragment>
  );
};
export default CreateItem;
