import React, { Fragment, useEffect, useState } from "react";
import ItemCalendarElement from "./ItemCalendarElement";
const LocationByItem = ({constants}) => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState([]);

  const getAllItems = async () => {
    try {
      const response = await fetch(constants.API_URL+"/item");
      const jsonData = await response.json();
      setItems(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
 
  useEffect(() => {
    getAllItems();
  }, []);
  return (
    <Fragment>
      <div className="">
        <div className="text-2xl font-bold ml-6 mb-4 mt-14">
          Locations Effectuées
        </div>
        <div className="flex flex-row justify-end mx-8 mb-6">
          <div className="relative text-gray-600">
            <input
              type="search"
              name="serch"
              placeholder="Rechercher"
              className="bg-white h-10 px-5 pr-10 w-96 rounded-md text-sm focus:outline-none focus:shadow-lg hover:shadow-lg transition-all duration-200"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            ></input>
            <div className="absolute right-0 top-0 mt-3 mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        {items
          .filter((item) => item.nom.toLowerCase().includes(search))
          .map((myItem, index) => (
            <ItemCalendarElement
              key={myItem.pk_item}
              constants={constants}
              item={myItem}
              index={index}
            ></ItemCalendarElement>
          ))}
      </div>
    </Fragment>
  );
};
export default LocationByItem;
