import React, { Fragment, useState } from "react";
import AdminBar from "./components/AdminBar";
import ListLocation from "./components/ListLocation";
import ListItemEdit from "./components/ListItemEdit";
import CreateItem from "./components/CreateItem";
import { getUser, removeUserSession } from "./Utils/Common";
import { useHistory } from "react-router";
import Account from "./components/Account";
import LocationByItem from "./components/LocationByItem";
import Aujourdhui from "./components/Aujourdhui";

const Admin = ({constants}) => {
  const user = getUser();
  const history = useHistory();
  const [view, setView] = useState(6);

  const handleDisconnect = () => {
    removeUserSession();
    history.push("/login");
  };
  const handleRedirect = () => {
    history.push("/");
  };

  return (
    <Fragment>
      <main className="bg-gray-200 font-inter">
        <div className="flex flex-col">
          <div className="bg-black h-20 w-full flex flex-row  items-center">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/header-logo.svg"}
                className="h-12 ml-10  mr-36"
                alt="logo de l'etat de fribourg"
              ></img>
            </div>
            <div className="text-white">Bienvenue, {user.name}</div>
            <div
              onClick={() => handleRedirect()}
              className="flex flex-row bg-white text-black p-2 ml-auto mx-6 rounded-md border-2 border-white cursor-pointer hover:bg-black hover:text-white transition-colors duration-200 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                />
              </svg>
              <div>Retourner sur le site</div>
            </div>

            <div
              onClick={() => handleDisconnect()}
              className=" flex flex-row bg-white text-black p-2 rounded-md mr-8 border-2 border-white cursor-pointer hover:bg-black hover:text-white transition-colors duration-200 ease-in-out"
            >
              <div>Déconnexion</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </div>
          </div>
          <div className="min-h-screen w-full">
            <div className="min-h-screen w-full flex flex-row">
              <AdminBar view={view} updateView={setView}></AdminBar>
              <div className="w-full">
                {view === 1 ? (
                  <ListItemEdit constants={constants} updateView={setView}></ListItemEdit>
                ) : view === 2 ? (
                  <ListLocation constants={constants}></ListLocation>
                ) : view === 3 ? (
                  <CreateItem constants={constants}></CreateItem>
                ) : view === 5 ? (
                  <LocationByItem constants={constants}></LocationByItem>
                ) :view === 4 ? (
                  <Account></Account>
                ) : view === 6 ? (
                  <Aujourdhui constants={constants}></Aujourdhui>
                ) :
                (
                  <ListItemEdit constants={constants}></ListItemEdit>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-black h-64 text-white flex  items-center justify-center pb-4">
        <div className="flex flex-row">
          <img
            src={process.env.PUBLIC_URL + "/assets/footer-logo.svg"}
            alt="logo de l'etat de fribourg"
            className="h-32 mr-36"
          ></img>
          <div className="font-bold">Grangeneuve 2021</div>
        </div>
      </footer>
    </Fragment>
  );
}
export default Admin;
