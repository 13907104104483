import React, { Fragment, useState } from "react";
import ItemElement from "./ItemElement";

const ListItem = ({
  constants,
  items,
  selectedItems,
  asyncEnd,
  asyncStart,
  updateSelectedItems,
  removeSelectedItem
}) => {
  const [search, setSearch] = useState([]);

  return (
    <Fragment>
      <div className="text-2xl font-bold ml-3 mb-8 mt-14">
        Equipement à louer
      </div>
      <div className="flex flex-row justify-end sticky top-0 md:static md:mr-8 ">
        <input
          type="search"
          name="serch"
          placeholder="Rechercher"
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          className="ml-auto bg-white h-10 px-5 pr-10 w-full md:w-96 rounded-md text-sm focus:outline-none focus:shadow-lg hover:shadow-lg transition-all duration-200 hidden md:inline"
        ></input>
      </div>
      <div className="flex  items-center justify-center flex-row flex-wrap ">
        {items.map((item) => (
          <ItemElement
            key={item.pk_item}
            constants={constants}
            item={item}
            selectedItems={selectedItems}
            asyncStart={asyncStart}
            asyncEnd={asyncEnd}
            updateSelectedItems={updateSelectedItems}
            display={item.nom.toLowerCase().includes(search) || item.description.toLowerCase().includes(search) ? true : false}
            removeSelectedItem={removeSelectedItem}
          />
        ))}
      </div>
    </Fragment>
  );
};
export default ListItem;
