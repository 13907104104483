import React, { Fragment, useEffect, useState } from "react";
const LocationElement = ({ constants,location, deleteLocation, index }) => {
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const getitemByLocation = async () => {
    try {
      const response = await fetch(
        constants.API_URL+"/itembylocation/" + location.pk_location
      );
      const jsonData = await response.json();
      setItems(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleClick = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };
  const handleDelete = () => {
    deleteLocation(location.pk_location);
  };
  useEffect(() => {
    getitemByLocation();
  }, []);
  let dateFin = new Date(location.fin);
  let dateFinString = dateFin.toLocaleString("fr-CH").split(",")[0];
  let dateDebut = new Date(location.debut);
  let dateDebutString = dateDebut.toLocaleString("fr-CH").split(",")[0];
  let isActiv = true;
  if (dateFin < Date.now()) {
    isActiv = false;
  }
  return (
    <Fragment>
      <div
        key={location.pk_location}
        className={
          "flex flex-col rounded-md p-2 m-4 pl-4  mx-8 transition-all duration-200 ease-in-out cursor-pointer hover:shadow-lg " +
          
          (isActiv ? "bg-white text-black " : "bg-gray-50 text-gray-400 ")
        }
        onClick={() => handleClick()}
      >
        <div className="flex flex-row items-center">
        <div className="flex flex-row text-sm mb-2">
          <div className="flex flex-col w-60 mr-8">
            <div className="text-lg font-bold">{location.nom}</div>
            <div>{location.email}</div>
          </div>
          <div  className="flex flex-col">
            <div className={"flex flex-row "}>
              <div className="w-40">Heure de passage :</div>
              <div>{location.pickup_time}</div>
            </div>
            <div className="flex flex-row">
              <div className="w-40">Date de début :</div>
              <div>{dateDebutString}</div>
            </div>
            <div className="flex flex-row">
              <div className="w-40">Date de fin</div>
              <div>{dateFinString}</div>
            </div>
          </div>
        </div>
          <div className="flex-grow">
            <button
              className="bg-black text-white text-sm border-2 border-black rounded-md ml-auto mr-2 p-3 hover:bg-white hover:text-red-500 transition-all duration-200 ease-in-out flex flex-row"
              onClick={() => handleDelete()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              <div className="ml-2">Supprimer</div>
            </button>
          </div>
        </div>
        <div className={"mt-4 " + (expanded ? "" : "hidden")}>
          <div className="border-b border-gray-200"></div>
          {items.map((item, index) => (
            <div
              key={item.pk_item}
              className={
                "flex flex-row ml-6 py-2 items-center rounded-md " 
              }
            >
              <img
                className="w-12 h-12 border-2 shadow-md border-white rounded-full"
                src={"app/public/uploads/" + item.image}
                alt="le produit"
              ></img>
              <div className="ml-6">{item.nom}</div>
            </div>
          ))}
          <div className="flex justify-end mx-4"></div>
        </div>
      </div>
    </Fragment>
  );
};
export default LocationElement;
