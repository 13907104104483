import React, {  Fragment, useState } from "react";
import axios from "axios";
import { setUserSession } from "./Utils/Common";
import { useHistory } from "react-router";

const Login = ({constants}) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();
  
  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios
      .post(constants.API_URL+"/users/login", {
        username: username,
        password: password,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(response.data.token, response.data.user);

        history.push("/admin");
      })
      .catch((err) => console.log(err));
  };
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <Fragment>
      <main className="bg-gray-200 font-inter">
        <div className="flex flex-col">
          <div className="bg-black h-20 w-full flex flex-row  items-center">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/header-logo.svg"}
                className="h-12 ml-10  mr-36"
                alt="logo de l'etat de fribourg"
              ></img>
            </div>
            <a
              href="../"
              className="text-white font-bold underline cursor-pointer"
            >
              Retourner sur le site
            </a>
          </div>
          <div className="min-h-screen w-full flex justify-center ">
            <div className="bg-white rounded-md shadow-md p-10 w-96 h-full mt-16">
              <div className="font-bold text-2xl mb-4">
                Connexion au panneau d'administration
              </div>
              <div>
                <div className="my-3">Nom d'utilisateur : </div>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                ></input>
              </div>
              <div>
                <div className="my-3">Mot de passe :</div>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => onKeyUp(e)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                ></input>
              </div>
              <div className="flex flex-row">
                <button
                  onClick={() => handleLogin()}
                  className="ml-auto mt-6 bg-black text-white p-3 rounded-md border-2 border-black hover:bg-white hover:text-black transition-colors ease-in-out duration-200"
                >
                  Connexion
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-black h-64 text-white flex  items-center justify-center pb-4">
        <div className="flex flex-row">
          <img
            src={process.env.PUBLIC_URL + "/assets/footer-logo.svg"}
            alt="logo de l'etat de fribourg"
            className="h-32 mr-36"
          ></img>
          <div className="font-bold">Grangeneuve 2021</div>
        </div>
      </footer>
    </Fragment>
  );
}
export default Login;
