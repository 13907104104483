import React, { Fragment, useState } from "react";
import ItemCalendarLocationElement from "./ItemCalendarLocationElement";
const ItemCalendarElement = ({ constants,item }) => {
  const [locations, setLocation] = useState([]);
  const [open, setOpen] = useState(false);
  const handleEdit = async (e) => {
    try {
      const response = await fetch(
        constants.API_URL+"/locationbyitem/" + item.pk_item
      );
      const jsonData = await response.json();
      setLocation(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleClick = (e) => {
    if(open){
        setOpen(false);
    }else{
        setOpen(true);
    }
    if(locations.length===0){
        handleEdit(e);
    }
  };
  return (
    <Fragment>
      <div className="flex flex-col bg-white rounded-xl p-3 mx-8 my-2 border border-gray-300 hover:shadow-lg transition-all duration-200">
        <div className="flex flex-row items-center">
          <div className="flex self-center justify-center w-44 flex-grow-0">
            <img
              className="w-16"
              src={"app/public/uploads/" + item.image}
              alt="le produit"
            ></img>
          </div>
          <div className="flex flex-col flex-grow">
            <div className="font-bold">{item.nom}</div>
          </div>
          <div
            className="bg-black rounded-md text-white flex flex-row p-3 border-2 border-black hover:bg-white hover:text-black cursor-pointer transition-colors ease-in-out "
            onClick={() => handleClick()}
          >
            <div>Voir les locations</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={"h-6 w-6 " + (open ? "hidden" : "")}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={"h-6 w-6 " + (open ? "" : "hidden")}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 11l7-7 7 7M5 19l7-7 7 7"
              />
            </svg>
          </div>
        </div>
        <div className={"mt-3 " + (open ? "" : "hidden")}>
            <div className={"bg-gray-100 p-3 text-gray-600 rounded-md border border-gray-400 " + (locations.length > 0 ? "hidden" : "")}>Il n'y a pas de location pour cet objet.</div>
          {locations.map((maLocation, index) => (
            <ItemCalendarLocationElement key={item.pk_item +'_'+ maLocation.pk_location}
            location={maLocation}
            index={index}></ItemCalendarLocationElement>
          ))}
        </div>
      </div>
    </Fragment>
  );
};
export default ItemCalendarElement;
