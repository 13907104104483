import React, { Fragment } from "react";
const ItemCalendarLocationElement = ({ location }) => {
  
  let isActiv =true;
  let dateFin = new Date(location.fin);
  let dateFinString = dateFin.toLocaleString("fr-CH").split(",")[0];
  let dateDebut = new Date(location.debut);
  let dateDebutString = dateDebut.toLocaleString("fr-CH").split(",")[0];
  if (dateFin < Date.now()) {
    isActiv = false;
  }
  return (
    <Fragment>
      <div className={"flex flex-row justify-evenly  rounded-md p-2 mb-2 "+ (isActiv ? "bg-yellow-200 border border-yellow-700 text-yellow-800" : "bg-gray-100 border border-gray-400 text-gray-500")}>
              <div>{location.nom}</div>
              <div>{location.email}</div>
              <div>{dateDebutString}</div>
              <div>{dateFinString}</div>
            </div>
    </Fragment>
  );
};
export default ItemCalendarLocationElement;
