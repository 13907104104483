import React, { Fragment, useEffect, useState } from "react";
import LocationElement from "./LocationElement";
const ListLocation = ({constants}) => {
  const [locations, setLocation] = useState([]);
  const [search, setSearch] = useState([]);

  const getAllLocation = async () => {
    try {
      const response = await fetch(constants.API_URL+"/location");
      const jsonData = await response.json();
      setLocation(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const deleteLocation = async (id) => {
    try {
      const response = await fetch(
        constants.API_URL+"/deleteLocation/" + id,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
      let newArray = [...locations];
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i].pk_location === id) {
          newArray.splice(i, 1);
        }
      }
      setLocation(newArray);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getAllLocation();
  }, []);
  return (
    <Fragment>
      <div className="">
        <div className="text-2xl font-bold ml-6 mb-4 mt-14">
          Locations Effectuées
        </div>
        <div className="flex flex-row justify-end mx-8 mb-6">
          <div className="relative text-gray-600">
            <input
              type="search"
              name="serch"
              placeholder="Rechercher"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              className="bg-white h-10 px-5 pr-10 w-96 rounded-md text-sm focus:outline-none focus:shadow-lg hover:shadow-lg transition-all duration-200"
            ></input>
            <div className="absolute right-0 top-0 mt-3 mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        {locations
          .filter((item) => item.nom.toLowerCase().includes(search))
          .map((maLocation, index) => (
            <LocationElement
              key={maLocation.pk_location}
              constants={constants}
              location={maLocation}
              deleteLocation={deleteLocation}
              index={index}
            ></LocationElement>
          ))}
      </div>
    </Fragment>
  );
};
export default ListLocation;
