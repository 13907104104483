import React, { Fragment } from "react";
const AdminBar = ({ view, updateView }) => {
  return (
    <Fragment>
      <div className="bg-white p-8 w-80">
        <div className="font-bold text-xl mb-4">Menu d'administration</div>
        <div className="ml-3">
        <div
            className={
              "cursor-pointer mb-2 flex flex-row my-8 " +
              (view === 6 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(6)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
</svg>
            <div className="ml-4">Aujourd'hui</div>
          </div>
          <div
            className={
              "cursor-pointer mb-2 flex flex-row my-8 " +
              (view === 1 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <div className="ml-4">Modifier les objets</div>
          </div>
          <div
            className={
              "cursor-pointer mb-2 flex flex-row my-8 " +
              (view === 3 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(3)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
            <div className="ml-4">Créer un objet</div>
          </div>
          <div
            className={
              "cursor-pointer mb-2 flex flex-row my-8 " +
              (view === 2 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(2)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <div className="ml-4">Voir les locations</div>
          </div>
          <div
            className={
              "cursor-pointer hover:text-moleson mb-2 flex flex-row my-8 " +
              (view === 5 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(5)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>
            <div className="ml-4">Location par objets</div>
          </div>
          <div
            className={
              "cursor-pointer hover:text-moleson mb-2 flex flex-row my-8 " +
              (view === 4 ? "text-moleson font-bold" : "hover:text-moleson")
            }
            onClick={() => updateView(4)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="ml-4">Mon compte</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AdminBar;
