import React, { Fragment} from "react";
import { getUser } from "../Utils/Common";
const Account = () => {
  const user = getUser();

  return (
    <Fragment>
      <div className="text-2xl font-bold ml-6 mb-4 mt-14">Mon compte</div>
      <div className="flex flex-col mx-8 bg-white rounded-xl p-3">
        <div className="m-2">
          <div className="font-bold mb-2">Nom</div>
          <div>{user.name}</div>
          <div className="font-bold mb-2 mt-3">Nom d'utilisateur</div>
          <div>{user.username}</div>
        </div>
      </div>
    </Fragment>
  );
};
export default Account;
