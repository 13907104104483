import React, { Fragment, useEffect, useState } from "react";
const LocationElementAujourdhui = ({ constants, location, date }) => {
  const [items, setItems] = useState([]);
  const getitemByLocation = async () => {
    try {
      const response = await fetch(
        constants.API_URL + "/itembylocation/" + location.pk_location
      );
      const jsonData = await response.json();
      setItems(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getitemByLocation();
  }, []);
  let dateFin = new Date(location.fin);
  let dateFinString = dateFin.toLocaleString("fr-CH").split(",")[0];
  let dateDebut = new Date(location.debut);
  let dateDebutString = dateDebut.toLocaleString("fr-CH").split(",")[0];
  let status = false;
  if (location.debut === date) {
    status = true;
  }
  return (
    <Fragment>
      <div className="flex flex-col bg-white rounded-md p-2 m-4 pl-4 border mx-8 shadow-sm">
        <div className="flex flex-row items-center">
          <div className="mr-3">
            {status ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                />
              </svg>
            )}
          </div>
          <div className="font-bold text-xl my-2">
            {status ? "Debut de location" : "Retour de location"}
          </div>
        </div>
        <div className="border-b border-gray-200 mb-3"></div>
        <div className="flex flex-row text-sm">
          <div className="flex flex-col w-60 mr-8">
            <div className="text-lg font-bold">{location.nom}</div>
            <div>{location.email}</div>
          </div>
          <div  className="flex flex-col">
            <div className={"flex flex-row "+ (status ? "" : "hidden")}>
              <div className="w-40">Heure de passage :</div>
              <div>{location.pickup_time}</div>
            </div>
            <div className="flex flex-row">
              <div className="w-40">Date de début :</div>
              <div>{dateDebutString}</div>
            </div>
            <div className="flex flex-row">
              <div className="w-40">Date de fin</div>
              <div>{dateFinString}</div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 mt-3"></div>
        <div className=" ">
          {items.map((item, index) => (
            <div
              key={"_" + item.pk_item}
              className="flex flex-row ml-6 py-2 items-center rounded-md "
            >
              <img
                className="w-12 h-12 border-2 shadow-md border-white rounded-full"
                src={"app/public/uploads/" + item.image}
                alt="le produit"
              ></img>
              <div className="ml-6">{item.nom}</div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};
export default LocationElementAujourdhui;
